import React from "react"
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap"
import * as Icon from "react-feather"
import {history} from "../../../history"
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

const handleNavigation = (e, path) => {
    e.preventDefault()
    history.push(path)
}
const exit = (props) => {
    if (props.user.token)
        props.logout()
}

const UserDropdown = props => {
    const routeProfile = props.user.roles === "admin" || props.user.roles === "customer"
    return (
        <DropdownMenu right>
            {routeProfile &&
            <DropdownItem
                tag="a"
                href="#"
                onClick={e => handleNavigation(e,
                    props.user.token ? "/" + props.user.roles + "/profile" : "/"
                )}>
                <Icon.User size={14} className="mr-50"/>
                <span className="align-middle">
                        <FormattedMessage id="EditProfile"/>
                    </span>
            </DropdownItem>
            }
            <DropdownItem divider/>
            <DropdownItem
                tag="a"
                onClick={e => exit(props)}>
                <Icon.Power size={14} className="mr-50"/>
                <span className="align-middle">
                    <FormattedMessage id="Logout"/>
                </span>
            </DropdownItem>
        </DropdownMenu>
    )
}

class NavbarUser extends React.PureComponent {
    state = {
        navbarSearch: false,
        langDropdown: false,
    }

    handleNavbarSearch = () => {
        this.setState({
            navbarSearch: !this.state.navbarSearch
        })
    }

    componentDidMount() {

    }

    handleLangDropdown = () =>
        this.setState({langDropdown: !this.state.langDropdown})

    render() {
        return (
            <nav className="d-flex justify-content-between w-100">
                <ul className="nav navbar-nav navbar-nav-user my-auto">

                </ul>
                <ul className="nav navbar-nav navbar-nav-user">
                    <li className="my-auto">
                        <Link className="mr-2 ml-2" to="/">
                            <Icon.Home size={22}/>
                        </Link>
                    </li>
                    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                            <div className="user-nav d-sm-flex d-none">
                              <span className="user-name text-bold-600">
                                {this.props.user.name + " " + this.props.user.lastname}
                              </span>
                            </div>
                            <span data-tour="user">

                        </span>
                        </DropdownToggle>
                        <UserDropdown {...this.props} />
                    </UncontrolledDropdown>
                </ul>
            </nav>
        )
    }
}

export default NavbarUser
