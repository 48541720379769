//import jwtDecode from "jwt-decode";

// const initialState = {
//     best_selling_products: [],
//     menu: [],
//     new_products: [],
//     sections: [],
//     special_offer_products: [],
//     sliders: []
// }
export const ui = (state = {}, action) => {
  switch (action.type) {
    case "UI": {
      return {
        category_parent: action.payload.category_parent,
        best_selling_products: action.payload.best_selling_products,
        contact_us: action.payload.contact_us,
        menu: action.payload.menu,
        new_products: action.payload.new_products,
        sections: action.payload.sections,
        list_of_cars: action.payload.list_of_cars,
        special_offer_products: action.payload.special_offer_products,
        sliders: action.payload.sliders,
        articles: action.payload.articles,
        visitors: action.payload.visitors,
        advertisement: action.payload.advertisement,
        services: action.payload.services,
        story: action.payload.story,
        batteries: action.payload.batteries,
        nano: action.payload.nano,
        saba: action.payload.saba,
        sepahan: action.payload.sepahan,
        service_locations: action.payload.service_locations,
      };
    }
    default: {
      return state;
    }
  }
};
